<template>
  <section>
    <sidebar-crew-member-form v-model="isAddNewCrewSidebarActive" :crew-member="crewMemberSelected" @add-crew-member="crewMembers.push($event)" />

    <div v-if="!crewMembers.length && !loadingCrewMembers">
      <b-row>
        <b-col cols="12">
          <b-alert variant="primary" show class="m-0">
            <div class="alert-body">
              <span>{{ $t('alert.crew.no_crew_yet') }}</span>
              <b-link v-if="$can('CREW_ADD')" class="alert-link" @click="addCrewMember()">
                {{ $t('crew.action.add_crew_member') }}
              </b-link>
            </div>
          </b-alert>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <!-- Table Container Card -->
      <b-card no-body>
        <b-row class="m-1">
          <b-col class="d-flex justify-content-end p-0" cols="12">
            <b-button
              v-if="$can('CREW_ADD')"
              class="d-flex align-items-center justify-content-center"
              @click="addCrewMember()"
            >
              <font-awesome-icon icon="user-plus" class="mr-50" />
              {{ $t('crew.action.add_crew_member') }}
            </b-button>
          </b-col>
        </b-row>

        <!--LIST-->
        <app-data-table
          ref="refCrewListTable"
          table-name="crew-list-table"
          :items="crewMembers"
          :fields="tableColumns"
          :overlay="loadingCrewMembers"
          :actions-table="{
            showEdit: $can('CREW_EDIT'),
            showDelete: $can('CREW_DELETE'),
          }"
          @edit="editCrewMember($event.item.id)"
          @delete="deleteCrewMember($event.item)"
        >
          <!-- Column: Crew -->
          <template #cell(name)="data">
            <div class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </div>
          </template>

          <!-- Column: Role TODO not used -->
          <template #cell(workrole)="data">
            <div class="text-nowrap">
              <font-awesome-icon class="mr-50" :class="`text-${resolveRoleVariant(data.item.workrole)}`" />
              {{ data.item.workrole }}
            </div>
          </template>

          <!-- Column: Status TODO not used -->
          <template #cell(status)="data">
            <b-badge pill :variant="`light-${resolveStatusVariant(data.item.status)}`" class="text-capitalize">
              {{ data.item.status }}
            </b-badge>
          </template>
        </app-data-table>
      </b-card>
    </div>
  </section>
</template>

<script>
import UIHelper from '@/helpers/ui'
import { fetchCrewMembersRequest, deleteCrewMemberRequest } from '@/request/globalApi/requests/crewMemberRequests'
import CrewMember from '@/models/CrewMember'
import AppDataTable from '@/components/AppDataTable.vue'
import SidebarCrewMemberForm from './sidebar/SidebarCrewMemberForm.vue'

export default {
  name: 'CrewList',

  components: {
    AppDataTable,
    SidebarCrewMemberForm,
  },

  mixins: [UIHelper],
  data() {
    return {
      tableColumns: [{ key: 'fullName', sortable: true }],
      loadingCrewMembers: true,
      isAddNewCrewSidebarActive: false,
      crewMembers: [],
      crewMemberAdd: new CrewMember(),
      crewMemberSelected: new CrewMember(),
    }
  },
  mounted() {
    this.APIFetchCrewMembers()
  },
  methods: {
    APIFetchCrewMembers() {
      this.loadingCrewMembers = true
      fetchCrewMembersRequest()
        .then(response => {
          const { crewMembers } = response.data
          this.crewMembers = crewMembers.map(member => new CrewMember(member))
        })
        .finally(() => {
          this.loadingCrewMembers = false
        })
    },
    addCrewMember() {
      this.crewMemberSelected = this.crewMemberAdd.clone()
      this.isAddNewCrewSidebarActive = true
    },
    editCrewMember(memberId) {
      const crewMemberToEdit = this.crewMembers.find(member => member.id === memberId)
      if (crewMemberToEdit.nationalityCountry.code) {
        this.crewMemberSelected = crewMemberToEdit
        this.isAddNewCrewSidebarActive = true
        return
      }

      this.loadingCrewMembers = true
      crewMemberToEdit.fetch().then(() => {
        this.crewMemberSelected = crewMemberToEdit
        this.isAddNewCrewSidebarActive = true
      }).finally(() => {
        this.loadingCrewMembers = false
      })
    },
    deleteCrewMember(crewMember) {
      const crewMemberToDeletete = this.crewMembers.find(member => member.id === crewMember.id)
      crewMemberToDeletete.delete().then(isConfirmed => {
        if (isConfirmed) {
          this.crewMembers = this.crewMembers.filter(member => crewMember.id !== member.id)
        }
      })
    },
    APIDeleteCrewMember(crewMember) {
      deleteCrewMemberRequest(crewMember.id, crewMember.name).then(isConfirmed => {
        if (isConfirmed) {
          this.crewMembers = this.crewMembers.filter(member => crewMember.id !== member.id)
        }
      })
    },
  },
}
</script>
